<template>
	<div id="cashbackEdit"  class="el-content">
		<a-form :label-col="{span:2}" :wrapper-col="{span:8}">
			<a-form-item label="商品类型">
				<a-radio-group v-model:value="form.good_type">
					<a-radio :value="1">商品</a-radio>
					<a-radio :value="2">土地</a-radio>
					<a-radio :value="3">认养</a-radio>
				</a-radio-group>
			</a-form-item>
			<a-form-item label="商品ID">
				<div v-if="form.good_type == 1">
					<a-input v-model:value="form.good_id" disabled>
						<template #addonAfter>
							<com-select-table-list @getData="e=>form.good_id= e.value.id">选择商品</com-select-table-list>
						</template>
					</a-input>	
				</div>
				<div v-if="form.good_type == 2">
					<a-input v-model:value="form.good_id" disabled>
						<template #addonAfter>
							<com-select-table-list data-type="land" @getData="e=>form.good_id= e.value.id">选择商品</com-select-table-list>
						</template>
					</a-input>	
				</div>
				<div v-if="form.good_type == 3">
					<a-input v-model:value="form.good_id" disabled>
						<template #addonAfter>
							<com-select-table-list data-type="adopt" @getData="e=>form.good_id= e.value.id">选择商品</com-select-table-list>
						</template>
					</a-input>	
				</div>
			</a-form-item>
			<a-form-item label="返现周期">
				<a-radio-group v-model:value="form.cash_type">
					<a-radio :value="1">一次性返现</a-radio>
					<a-radio :value="2">每天返现</a-radio>
					<a-radio :value="3">每周返现</a-radio>
				</a-radio-group>
			</a-form-item>
			<a-form-item v-if="form.cash_type !=1">
				<template #label>
					<span>返现次数</span>
					<a-tooltip title="注意:返现次数必须大于0">
						<i class="ri-information-line ri-top"></i>
					</a-tooltip>
				</template>
				<a-input v-model:value="form.end_time" type="number" :min="1"></a-input>
			</a-form-item>
			<a-form-item label="返现金额" required>
				<a-input type="text" v-model:value="form.money"></a-input>
			</a-form-item>
			<a-form-item label="是否上架">
				<a-radio-group v-model:value="form.is_put_away">
					<a-radio :value="1">是</a-radio>
					<a-radio :value="0">否</a-radio>
				</a-radio-group>
			</a-form-item>
			<a-form-item :wrapper-col="{offset:2}">
				<a-space>
					<a-button type="primary" @click="submitSave">保存</a-button>
					<a-button @click="$router.go(-1)">返回</a-button>
				</a-space>
			</a-form-item>
		</a-form>
	</div>
</template>
<script>
import  comSelectTableList from "@/components/miniapp/com-select-table-list.vue"
import { reactive, toRefs } from 'vue';
import cashbackModel from '@/api/addons/cashback';
import { useRoute } from 'vue-router';
export default{
	components:{
		comSelectTableList
	},
	setup(){
		const state = reactive({
			form:{
				id:0,
				good_type:1,
				good_id:0,
				cash_type:1,
				end_time:0,
				status:1,
				money:0,
				is_put_away:1,
			},
		})
		const opt = useRoute().query
		if( opt.id ) cashbackModel.getCashBackDetail(opt.id,Object.keys(state.form),res=>state.form = res)
		const submitSave = ()=>cashbackModel.addOrEditCashback(state.form)
		return{
			...toRefs(state),
			submitSave
		}
	}
}
</script>

<style lang="scss">
	.cashback-goodsinfo{
		display: flex;
		align-items: center;
		
		.cg-cover{
			width: 30px;
			height: 30px;
			margin-right: 10px;
		}
	}
</style>
